<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link
        class="brand-logo d-none d-lg-block"
        to="/"
      >
        <b-img
          fluid
          :src="appLogoImage"
          alt="Register V2"
          width="150"
          height="150"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto mt-3"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Form Register
          </b-card-title>
          <b-card-text class="mb-2">
            Silahkan lengkapi form dibawah ini
          </b-card-text>

          <!-- form -->
          <form>
            <validation-observer ref="registerForm">
              <b-form class="auth-register-form mt-2">
                <!-- username -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <label
                      class=""
                      for="inline-form-input-name"
                    >Nama <span class="text-danger">*</span></label>
                    <b-form-input
                      id="register-username"
                      v-model="form.name"
                      name="register-username"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Nama lengkap"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- <b-row>
                  <b-col sm="6">
                    <b-form-group
                      label="Gelar Depan"
                      label-for="register-gelar-depan"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="gelar_depan"
                      >
                        <b-form-input
                          id="register-gelar-depan"
                          v-model="form.gelar_depan"
                          name="register-gelar-depan"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Gelar depan"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      label="Gelar Belakang"
                      label-for="register-gelar-belakang"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="gelar_belakang"
                      >
                        <b-form-input
                          id="register-gelar-belakang"
                          v-model="form.gelar_belakang"
                          name="register-gelar-belakang"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Gelar belakang"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row> -->

                <!-- email -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <label
                      class=""
                      for="inline-form-input-name"
                    >Email <span class="text-danger">*</span></label>
                    <b-form-input
                      id="register-email"
                      v-model="form.email"
                      name="register-email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Alamat email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    rules="required"
                  >
                    <label
                      class=""
                      for="inline-form-input-name"
                    >Password <span class="text-danger">*</span></label>
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="register-password"
                        v-model="form.password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        name="form.password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password confirm -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="password_confirmation"
                    rules="required"
                  >
                    <label
                      class=""
                      for="inline-form-input-name"
                    >Konfirmasi Password
                      <span class="text-danger">*</span></label>
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="register-password_confirmation"
                        v-model="form.password_confirmation"
                        class="form-control-merge"
                        :type="passwordConfirmFieldType"
                        :state="errors.length > 0 ? false : null"
                        name="register-password_confirmation"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordConfirmToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordConfirmVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- phone -->
                <b-form-group
                  label="No HP"
                  label-for="register-phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="phone"
                  >
                    <b-form-input
                      id="register-phone"
                      v-model="form.phone"
                      name="register-phone"
                      :state="errors.length > 0 ? false : null"
                      placeholder="628123456789"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- address -->
                <b-form-group
                  label="Alamat"
                  label-for="register-address"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="address"
                  >
                    <b-form-input
                      id="register-address"
                      v-model="form.address"
                      name="register-address"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Alamat"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Tempat & Tanggal Lahir"
                  label-for="register-tempat_lahir"
                >
                  <b-row>
                    <b-col>
                      <validation-provider
                        #default="{ errors }"
                        name="tempat_lahir"
                        rules="required"
                      >
                        <b-form-input
                          id="register-tempat_lahir"
                          v-model="form.tempat_lahir"
                          name="register-tempat_lahir"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Tempat lahir"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col>
                      <validation-provider
                        #default="{ errors }"
                        name="tanggal_lahir"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="form.tanggal_lahir"
                          class="form-control"
                          :class="{
                            'is-invalid': errors.length > 0 ? false : null,
                          }"
                          placeholder="Tanggal lahir"
                          :config="{
                            altInput: true,
                            altFormat: 'j F Y',
                            dateFormat: 'Y-m-d',
                            maxDate: 'today'
                          }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  label="Pekerjaan"
                  label-for="register-pekerjaan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="pekerjaan"
                  >
                    <b-form-select
                      v-model="form.pekerjaan"
                      :options="[
                        'Pelajar',
                        'Mahasiswa',
                        'Fresh Graduate',
                        'Karyawan',
                        'Wirausaha/Bisnis',
                        'Lainnya'
                      ]"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Mendapatkan info Tekov dari"
                  label-for="register-nip"
                >
                  <validation-provider
                    #default="{ errors }"
                    name=" "
                  >
                    <b-form-select
                      v-model="form.informasi_dari"
                      :options="[
                        'Media Sosial',
                        'Iklan',
                        'Rekomendasi Teman',
                        'Trainer',
                        'Googling'
                      ]"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- <b-form-group label="NIK" label-for="register-nik">
                  <validation-provider #default="{ errors }" name="nik">
                    <b-form-input
                      id="register-nik"
                      v-model="form.nik"
                      name="register-nik"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Nomor Induk Kependudukan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group> -->

                <b-form-group
                  label="Masukkan kode dibawah"
                  label-for="register-captcha"
                >
                  <app-captcha @captchaValue="setCaptcha" />
                  <validation-provider
                    #default="{ errors }"
                    name="captcha"
                    :rules="'required|is:' + captcha"
                  >
                    <b-form-input
                      id="register-captcha"
                      v-model="inputCaptcha"
                      name="register-captcha"
                      placeholder="Masukkan kode diatas"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link>privacy policy & terms</b-link>
                </b-form-checkbox>
              </b-form-group> -->

                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="isLoading"
                  @click.prevent="validationForm"
                >
                  {{ isLoading ? "mendaftar..." : "Daftar" }}
                </b-button>
              </b-form>
            </validation-observer>
          </form>

          <p class="text-center mt-2">
            <span>Sudah memiliki akun?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp;Masuk</span>
            </b-link>
          </p>

          <!-- divider -->
          <!--  <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import AppCaptcha from '@core/components/app-captcha/index.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormSelect,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

import { required, email, is } from 'vee-validate/dist/rules'
import {
  togglePasswordVisibility,
  togglePasswordConfirmVisibility,
} from '@core/mixins/ui/forms'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { reqRegister } from '@/api/auth'

extend('is', is)
extend('required', required)
extend('email', email)

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    AppCaptcha,
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmVisibility],
  data() {
    return {
      status: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      form: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        phone: '',
        address: '',
        gelar_depan: '',
        gelar_belakang: '',
        tempat_lahir: '',
        tanggal_lahir: '',
        golongan: '',
        pekerjaan: '',
        nik: '',
        informasi_dari: '',
      },
      captcha: '',
      inputCaptcha: '',
      isLoading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    setCaptcha(value) {
      this.captcha = value
    },
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          reqRegister(this.form)
            .then(() => {
              this.isLoading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil Register',
                  text: 'Silahkan login untuk mulai menggunakan sistem',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              this.isLoading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Gagal daftar',
                  text: error.response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
              this.$refs.registerForm.setErrors(error.response.data.errors)
            })
        }
      })
    },
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app

    return {
      appName,
      appLogoImage,
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "~@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
