var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo d-none d-lg-block",attrs:{"to":"/"}},[_c('b-img',{attrs:{"fluid":"","src":_vm.appLogoImage,"alt":"Register V2","width":"150","height":"150"}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto mt-3",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Form Register ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Silahkan lengkapi form dibawah ini ")]),_c('form',[_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"inline-form-input-name"}},[_vm._v("Nama "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false : null,"placeholder":"Nama lengkap"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"inline-form-input-name"}},[_vm._v("Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"Alamat email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"inline-form-input-name"}},[_vm._v("Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"form.password","placeholder":"············"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"password_confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"inline-form-input-name"}},[_vm._v("Konfirmasi Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password_confirmation","type":_vm.passwordConfirmFieldType,"state":errors.length > 0 ? false : null,"name":"register-password_confirmation","placeholder":"············"},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordConfirmToggleIcon},on:{"click":_vm.togglePasswordConfirmVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"No HP","label-for":"register-phone"}},[_c('validation-provider',{attrs:{"name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-phone","name":"register-phone","state":errors.length > 0 ? false : null,"placeholder":"628123456789"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Alamat","label-for":"register-address"}},[_c('validation-provider',{attrs:{"name":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-address","name":"register-address","state":errors.length > 0 ? false : null,"placeholder":"Alamat"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tempat & Tanggal Lahir","label-for":"register-tempat_lahir"}},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"tempat_lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-tempat_lahir","name":"register-tempat_lahir","state":errors.length > 0 ? false : null,"placeholder":"Tempat lahir"},model:{value:(_vm.form.tempat_lahir),callback:function ($$v) {_vm.$set(_vm.form, "tempat_lahir", $$v)},expression:"form.tempat_lahir"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"tanggal_lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",class:{
                          'is-invalid': errors.length > 0 ? false : null,
                        },attrs:{"placeholder":"Tanggal lahir","config":{
                          altInput: true,
                          altFormat: 'j F Y',
                          dateFormat: 'Y-m-d',
                          maxDate: 'today'
                        }},model:{value:(_vm.form.tanggal_lahir),callback:function ($$v) {_vm.$set(_vm.form, "tanggal_lahir", $$v)},expression:"form.tanggal_lahir"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Pekerjaan","label-for":"register-pekerjaan"}},[_c('validation-provider',{attrs:{"name":"pekerjaan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":[
                      'Pelajar',
                      'Mahasiswa',
                      'Fresh Graduate',
                      'Karyawan',
                      'Wirausaha/Bisnis',
                      'Lainnya'
                    ]},model:{value:(_vm.form.pekerjaan),callback:function ($$v) {_vm.$set(_vm.form, "pekerjaan", $$v)},expression:"form.pekerjaan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Mendapatkan info Tekov dari","label-for":"register-nip"}},[_c('validation-provider',{attrs:{"name":" "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":[
                      'Media Sosial',
                      'Iklan',
                      'Rekomendasi Teman',
                      'Trainer',
                      'Googling'
                    ]},model:{value:(_vm.form.informasi_dari),callback:function ($$v) {_vm.$set(_vm.form, "informasi_dari", $$v)},expression:"form.informasi_dari"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Masukkan kode dibawah","label-for":"register-captcha"}},[_c('app-captcha',{on:{"captchaValue":_vm.setCaptcha}}),_c('validation-provider',{attrs:{"name":"captcha","rules":'required|is:' + _vm.captcha},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-captcha","name":"register-captcha","placeholder":"Masukkan kode diatas"},model:{value:(_vm.inputCaptcha),callback:function ($$v) {_vm.inputCaptcha=$$v},expression:"inputCaptcha"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.isLoading ? "mendaftar..." : "Daftar")+" ")])],1)],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Sudah memiliki akun?")]),_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('span',[_vm._v(" Masuk")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }