<template>
  <div>
    <div class="np-captcha-container unselectable">
      <div
        v-if="captcha && captcha.length"
        class="np-captcha"
      >
        <div
          v-for="(c, i) in captcha"
          :key="i"
          :style="{
            fontSize: getFontSize() + 'px',
            fontWeight: 800,
            transform: 'rotate(' + getRotationAngle() + 'deg)',
          }"
          class="np-captcha-character"
        >
          {{ c }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      captchaLength: 4,
      captcha: [],
    }
  },
  mounted() {
    this.createCaptcha()
  },
  methods: {
    createCaptcha() {
      let tempCaptcha = ''
      for (let i = 0; i < this.captchaLength; i++) {
        tempCaptcha += this.getRandomCharacter()
      }
      this.captcha = tempCaptcha.split('')
      this.$emit('captchaValue', tempCaptcha)
    },
    getRandomCharacter() {
      const symbols = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      const randomNumber = Math.floor(Math.random() * 36)
      return symbols[randomNumber]
    },
    getFontSize() {
      const fontVariations = [14, 20, 30, 36, 40]
      return fontVariations[Math.floor(Math.random() * 5)]
    },
    getRotationAngle() {
      const rotationVariations = [5, 10, 20, 25, -5, -10, -20, -25]
      return rotationVariations[Math.floor(Math.random() * 8)]
    },
  },
}
</script>

<style>
.np-captcha-container {
  background: #eee;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
}
.np-captcha {
  font-size: 24px;
}
.np-button {
  padding: 6px 10px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  font-size: 16px;
}
.np-captcha-character {
  display: inline-block;
  letter-spacing: 14px;
}
.unselectable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>
